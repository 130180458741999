import {
  validateEmail,
  validatePdfOrWord,
  validateRequired,
  Validator,
  validate5MbMaxFileSize,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SUBMIT_FORM_JOIN_DELIVERY_FLEET = '[form] submit form delivery fleet';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_JOIN_DELIVERY_FLEET;
}
export const submitForm = {
  type: SUBMIT_FORM_JOIN_DELIVERY_FLEET,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_JOIN_DELIVERY_FLEET = '[forms] set loading delivery fleet';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_JOIN_DELIVERY_FLEET;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_JOIN_DELIVERY_FLEET,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_JOIN_DELIVERY_FLEET = '[forms] set success delivery fleet';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_JOIN_DELIVERY_FLEET;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_JOIN_DELIVERY_FLEET,
  payload: {
    isSuccess,
  },
});

// eslint-disable-next-line max-len
export const SET_INPUT_JOIN_DELIVERY_FLEET = '[forms] input change join delivery';

export type SetInput = {
  type: typeof SET_INPUT_JOIN_DELIVERY_FLEET;
  payload: {
    fieldName: string;
    value?: string | File;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_JOIN_DELIVERY_FLEET;
  payload: {
    [fieldName: string]: {
      value?: string | File;
      error: string;
    }
  }
}

export const setInput = (fieldName: string) => (
  value?: string | File,
): SetInput => ({
  type: SET_INPUT_JOIN_DELIVERY_FLEET,
  payload: {
    fieldName,
    value,
    validators: [],
  },
});

export const setRequiredInput = (fieldName: string) => (
  value?: string,
): SetInput => ({
  type: SET_INPUT_JOIN_DELIVERY_FLEET,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});
export const setRequiredEmail = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_JOIN_DELIVERY_FLEET,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validateEmail],
  },
});
export const setRequiredPdfOrWordFile = (fieldName: string) => (
  value?: File,
): SetInput => ({
  type: SET_INPUT_JOIN_DELIVERY_FLEET,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validatePdfOrWord, validate5MbMaxFileSize],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_JOIN_DELIVERY_FLEET = '[forms] phone number change delivery fleet';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_JOIN_DELIVERY_FLEET;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_JOIN_DELIVERY_FLEET;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_JOIN_DELIVERY_FLEET,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

// eslint-disable-next-line max-len
export const SET_CHECKBOX_JOIN_DELIVERY_FLEET = '[forms] checkbox change join delivery fleet';

export type SetCheckbox = {
  type: typeof SET_CHECKBOX_JOIN_DELIVERY_FLEET;
  payload: {
    groupName: string;
    option: string;
    isChecked: boolean;
    validators: Validator[];
  }
}
export type SetCheckboxValidated = {
  type: typeof SET_CHECKBOX_JOIN_DELIVERY_FLEET;
  payload: {
    [groupName: string]: {
      value: { [key: string]: boolean; };
      error: string;
    }
  }
}
export const setRequiredCheckbox = (
  groupName: string,
  option: string,
) => (
  isChecked: boolean,
): SetCheckbox => ({
  type: SET_CHECKBOX_JOIN_DELIVERY_FLEET,
  payload: {
    groupName,
    option,
    isChecked,
    validators: [validateRequired],
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_JOIN_DELIVERY_FLEET = 'set general error join delivery fleet';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_JOIN_DELIVERY_FLEET;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_JOIN_DELIVERY_FLEET,
  payload: message,
});

// eslint-disable-next-line max-len
export const SHOW_SECOND_STEP_JOIN_DELIVERY_FLEET = '[forms] show 2nd step delivery fleet';

export type ShowSecondStep = {
  type: typeof SHOW_SECOND_STEP_JOIN_DELIVERY_FLEET,
};
export const showSecondStep = {
  type: SHOW_SECOND_STEP_JOIN_DELIVERY_FLEET,
};

export const RESET_FORM_JOIN_DELIVERY_FLEET = '[join-df] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_JOIN_DELIVERY_FLEET,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_JOIN_DELIVERY_FLEET,
};

export type JoinDeliveryFleetActions =
| SetInputValidated
| SetPhoneInputValidated
| SetFormLoading
| SetFormSuccess
| SetCheckboxValidated
| SetGeneralErrorMessage
| ResetForm;
