import { Middleware } from 'redux';
import { Store } from 'application/middleware';
import { Services } from 'application/interfaces';

import { AppState } from 'src/application/state';
import { AppError } from 'src/application/entities/app-error';
import {
  validate5MbMaxFileSize,
  validateEmail,
  validatePdfOrWord,
  validateRequired,
} from 'application/utils/validators';
import {
  SetCheckbox,
  SetCheckboxValidated,
  setFormLoading,
  setFormSuccess,
  SetInput,
  SetInputValidated,
  SetPhoneInput,
  SetPhoneInputValidated,
  setRequiredCheckbox,
  setRequiredEmail,
  setRequiredInput,
  setRequiredPdfOrWordFile,
  setRequiredPhoneInput,
  SET_INPUT_JOIN_DELIVERY_FLEET,
  SET_PHONE_INPUT_JOIN_DELIVERY_FLEET,
  SubmitForm,
  SUBMIT_FORM_JOIN_DELIVERY_FLEET,
  SET_CHECKBOX_JOIN_DELIVERY_FLEET,
  setGeneralErrorMessage,
} from './actions';

export const InputChanged = (
): Middleware => () => (next) => (action: SetInput): void => {
  if (action.type === SET_INPUT_JOIN_DELIVERY_FLEET) {
    const { payload } = action as SetInput;

    let error = '';
    payload.validators.forEach((validate) => {
      let errorCode = '';
      if (
        typeof payload.value === 'string'
          || typeof payload.value === 'undefined') {
        errorCode = validate(payload.value);
      } else {
        // assuming payload.value is File type
        errorCode = validate(payload?.value);
      }
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetInputValidated['payload'] = {
      [payload.fieldName]: {
        value: payload.value,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const PhoneInputChanged = (
): Middleware => () => (next) => (action): void => {
  if (action.type === SET_PHONE_INPUT_JOIN_DELIVERY_FLEET) {
    const { payload } = action as SetPhoneInput;

    const validatedPayload: SetPhoneInputValidated['payload'] = {
      phoneNumber: {
        countryCode: payload.countryCode,
        number: payload.number,
        error: payload.isRequired ? validateRequired(payload.number) : '',
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const CheckboxChanged = (): Middleware => ({
  getState,
}: { getState: () => AppState }) => (next) => (action: SetCheckbox): void => {
  if (action.type === SET_CHECKBOX_JOIN_DELIVERY_FLEET) {
    const {
      payload: {
        groupName,
        option,
        isChecked,
        validators,
      },
    } = action as SetCheckbox;

    let error = '';
    const existingBoxes = (
      getState().joinDeliveryFleet as {[key: string]: any} // eslint-disable-line
    )[groupName].value as {[key: string]: boolean};

    const updatedBoxes = {
      ...existingBoxes,
      [option]: isChecked,
    };

    validators.forEach((validate) => {
      const errorCode = validate(updatedBoxes);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetCheckboxValidated['payload'] = {
      [groupName]: {
        value: updatedBoxes,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const FormSubmitted = (
  { api }: Services,
): Middleware => ({
  dispatch,
  getState,
}: Store) => (next) => async (action: SubmitForm): Promise<void> => {
  next(action);

  if (action.type === SUBMIT_FORM_JOIN_DELIVERY_FLEET) {
    dispatch(setFormLoading(true));

    if (!api?.hr?.joinDeliveryFleet) {
      return;
    }

    const state = getState().joinDeliveryFleet;

    if (!state) throw new Error('Failed loading the module');

    const {
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      vehicle,
      currentLocation,
      resume,
      currentDesignation,
      inviteCode,
      isPolicyChecked,
      recaptcha,
    } = state;

    let canSubmit = true;

    dispatch(setGeneralErrorMessage(''));
    if (validateRequired(firstName.value)) {
      dispatch(setRequiredInput('firstName')(firstName.value));
      canSubmit = false;
    }
    if (validateRequired(lastName.value)) {
      dispatch(setRequiredInput('lastName')(lastName.value));
      canSubmit = false;
    }
    if (validateRequired(phoneNumber.number)) {
      dispatch(setRequiredPhoneInput(
        phoneNumber.countryCode,
        phoneNumber.number,
      ));
      canSubmit = false;
    }
    if (validateEmail(email.value)) {
      dispatch(setRequiredEmail('email')(email.value));
      canSubmit = false;
    }
    if (validateRequired(currentLocation.value)) {
      dispatch(setRequiredInput('currentLocation')(currentLocation.value));
      canSubmit = false;
    }
    if (
      validateRequired(resume?.value?.name)
      || validatePdfOrWord(resume.value)
      || validate5MbMaxFileSize(resume.value)
    ) {
      dispatch(setRequiredPdfOrWordFile('resume')(resume.value));
      canSubmit = false;
    }
    if (validateRequired(vehicle.value)) {
      dispatch(setRequiredInput('vehicle')(vehicle.value));
      canSubmit = false;
    }
    if (validateRequired(isPolicyChecked.value)) {
      dispatch(setRequiredCheckbox(
        'isPolicyChecked', 'agreed',
      )(false));
      canSubmit = false;
    }
    if (validateRequired(recaptcha.value)) {
      dispatch(setRequiredInput('recaptcha')(recaptcha.value));
      canSubmit = false;
    }

    if (!canSubmit || !resume.value) {
      dispatch(setFormLoading(false));
      return;
    }

    try {
      await api.hr.joinDeliveryFleet({
        title: title.value,
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber,
        email: email.value,
        vehicle: vehicle.value,
        currentDesignation: currentDesignation.value,
        currentLocation: currentLocation.value,
        inviteCode: inviteCode.value,
        isPolicyChecked: isPolicyChecked.value.agreed,
        resume: resume.value,
      });

      dispatch(setFormSuccess(true));
    } catch (error) {
      if (error instanceof AppError && error.code === 'validationError') {
        dispatch(setGeneralErrorMessage(error.description));
      } else {
        dispatch(setGeneralErrorMessage(
          error.description || error.message,
        ));
      }
      // eslint-disable-next-line no-console
      console.error(error.message);
    }

    dispatch(setFormLoading(false));
  }
};

export default [
  InputChanged,
  PhoneInputChanged,
  FormSubmitted,
  CheckboxChanged,
];
